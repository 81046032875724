import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [0,2,11,4,18,10,19];

export const dictionary = {
		"/(app)": [22,[2]],
		"/(spa)/(brokerage)/accounts-payable": [44,[11,12]],
		"/(spa)/(brokerage)/accounts-receivable": [45,[11,12]],
		"/(spa)/(brokerage)/brokerage": [46,[11,12,13]],
		"/(spa)/(brokerage)/brokerage/settings": [47,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/branding": [48,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/carrier-agreement": [49,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/commission": [50,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/dat-integration": [51,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/highway-integration": [52,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/leads": [53,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/macropoint-integration": [54,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/mcp-integration": [55,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/quickbooks-online": [56,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/rmis-integration": [57,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/trucker-tools-integration": [58,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/truckstop-integration": [59,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/users": [60,[11,12,13]],
		"/(spa)/(brokerage)/carriers": [61,[11,12]],
		"/(spa)/(brokerage)/carriers/search": [68,[11,12]],
		"/(spa)/(brokerage)/carriers/[carrierId]": [62,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/activity-log": [63,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/compliance": [64,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/contacts": [65,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/load-history": [66,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/payment-info": [67,[11,12,15]],
		"/(spa)/(brokerage)/commissions": [69,[11,12]],
		"/(public)/(with-layout)/confirmed": [32,[,7],[6]],
		"/(spa)/(brokerage)/customer-management": [70,[11,12,16]],
		"/(spa)/(brokerage)/customer-management/customers": [71,[11,12,16]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]": [72,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/activity-log": [73,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/contacts": [74,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/edi-log": [75,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/lead-info": [76,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/order-history": [~77,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/charge-codes": [78,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/references": [79,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/shipping-locations": [80,[11,12,17]],
		"/(spa)/(brokerage)/customer-management/quotes": [81,[11,12,16]],
		"/(spa)/(brokerage)/customers": [82,[11,12]],
		"/(spa)/(brokerage)/dashboard": [83,[11,12]],
		"/(app)/documents": [23,[2]],
		"/(public)/(with-layout)/forgot-password": [33,[,7],[6]],
		"/(spa)/internal/brokerages": [98,[11,20]],
		"/(spa)/internal/brokerages/[organizationId]": [99,[11,20,21]],
		"/(spa)/internal/brokerages/[organizationId]/activity-log": [100,[11,20,21]],
		"/(spa)/internal/brokerages/[organizationId]/users": [101,[11,20,21]],
		"/(spa)/internal/brokerages/[organizationId]/users/[userId]": [102,[11,20,21]],
		"/(spa)/internal/charge-codes": [103,[11,20]],
		"/(spa)/internal/references": [104,[11,20]],
		"/(spa)/internal/special-requirements": [105,[11,20]],
		"/(app)/loads": [24,[2,3]],
		"/(app)/loads/[loadId]": [25,[2,3,4]],
		"/(app)/location": [26,[2]],
		"/(public)/(with-layout)/login": [34,[,7],[6]],
		"/(app)/logout": [~27,[2]],
		"/(public)/oauth2/truckstop": [36,[],[6]],
		"/(app)/orders": [~28,[2,5]],
		"/(app)/orders/drafts": [~29,[2,5]],
		"/(app)/orders/drafts/[orderId]": [~30,[2,5]],
		"/(app)/orders/old": [31,[2,5]],
		"/(public)/(with-layout)/password-reset/[username]": [~35,[,7],[6]],
		"/(public)/payment-complete": [~37,[],[6]],
		"/(public)/pricing": [38,[,8],[6]],
		"/(spa)/(brokerage)/shipments": [~84,[11,12]],
		"/(spa)/(brokerage)/shipments/[shipmentId]": [85,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/activity-log": [86,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/check-calls": [87,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/details": [88,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/documents": [89,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/edi-log": [90,[11,12,18]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/financials": [91,[11,12,18]],
		"/(public)/sign/[dsrId]": [~39,[,9],[6]],
		"/(public)/tracking/[shipmentId]": [40,[,10],[6]],
		"/(public)/tracking/[shipmentId]/check-calls": [41,[,10],[6]],
		"/(public)/tracking/[shipmentId]/documents": [42,[,10],[6]],
		"/(public)/tracking/[shipmentId]/edi-log": [43,[,10],[6]],
		"/(spa)/(brokerage)/vendors": [92,[11,12]],
		"/(spa)/(brokerage)/vendors/[vendorId]": [93,[11,12,19]],
		"/(spa)/(brokerage)/vendors/[vendorId]/activity-log": [94,[11,12,19]],
		"/(spa)/(brokerage)/vendors/[vendorId]/contacts": [95,[11,12,19]],
		"/(spa)/(brokerage)/vendors/[vendorId]/documents": [96,[11,12,19]],
		"/(spa)/(brokerage)/vendors/[vendorId]/locations": [97,[11,12,19]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';